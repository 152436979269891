import React, { useState, useCallback } from 'react';
import { Maps } from '@/components/Maps';
import './style.scss';
import { MapControl } from '@/components/Maps/_components/MapControl';
import { Button, Collapse, Hidden, Paper } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AuthState } from '@/modules/auth';

const DashboardPage = () => {
  const { logout, isIFrame } = AuthState.useContainer();
  const [expanded, setExpanded] = useState(true);
  const toggleExpand = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);
  return (
    <div className="container">
      {isIFrame ? null : (
        <div className="logout-button-container">
          <Button
            variant="contained"
            onClick={() => logout()}
            className="logout-button"
            startIcon={<ExitToAppIcon />}
          >
            Log out
          </Button>
        </div>
      )}
      <Hidden mdUp implementation="css">
        <div className="map-control-container-md">
          <Button
            fullWidth
            variant="outlined"
            onClick={toggleExpand}
            classes={{ root: 'toggle-control-button' }}
          >
            {expanded ? 'Hide' : 'Show'} Control & Filters
          </Button>
          <Collapse in={expanded}>
            <Paper>
              <MapControl />
            </Paper>
          </Collapse>
        </div>
      </Hidden>
      <Hidden smDown implementation="css">
        <div
          style={{
            marginTop: expanded ? 8 : 40,
            marginBottom: 24,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Collapse in={expanded}>
            <Paper>
              <MapControl />
            </Paper>
          </Collapse>
          <Button
            fullWidth
            variant="outlined"
            onClick={toggleExpand}
            classes={{ root: 'toggle-control-button' }}
          >
            {expanded ? 'Hide' : 'Show'} Control & Filters
          </Button>
        </div>
      </Hidden>
      <Maps />
    </div>
  );
};

export { DashboardPage };

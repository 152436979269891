/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo, useCallback } from 'react';
import { Typography, Slider, Tooltip, Mark } from '@material-ui/core';
import { format, differenceInHours, addHours, addDays, isAfter, endOfDay } from 'date-fns';
import { useStore } from '@/stores/rootStore';
import { useObserver } from 'mobx-react-lite';

import './style.scss';

// interface TimeframeSliderProps {}

function slideText(value: number) {
  return `${value}`;
}

function ValueLabelComponent(props: any) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const TimeframeSlider = memo(() => {
  const { mapControlStore, filterStore } = useStore();

  const timeframeMarks = useMemo(() => {
    let date = new Date(filterStore.currentStartDate);
    const dateMarks: Mark[] = [];
    const endDate = endOfDay(filterStore.currentEndDate);
    while (isAfter(endDate, date)) {
      dateMarks.push({
        value: differenceInHours(date, filterStore.currentStartDate),
      });

      date = addDays(date, 1);
    }

    return dateMarks;
  }, [filterStore.currentStartDate, filterStore.currentEndDate]);

  const handleSlideTimeframe = useCallback(
    (e: any, newValue: any) => {
      const newDate = addHours(filterStore.currentStartDate, newValue);
      mapControlStore.setRenderDateTime(newDate, true);
    },
    [filterStore.currentStartDate, mapControlStore]
  );

  return useObserver(() => (
    <>
      <div className="row control-slider">
        <Slider
          classes={{
            root: 'date-slider-base',
            thumb: 'date-slider-thumb',
          }}
          value={differenceInHours(
            mapControlStore.currentRenderDateTime,
            filterStore.currentStartDate
          )}
          min={0}
          max={differenceInHours(
            endOfDay(filterStore.currentEndDate),
            filterStore.currentStartDate
          )}
          step={null}
          getAriaValueText={slideText}
          valueLabelDisplay="auto"
          valueLabelFormat={(value: number, index: number) =>
            format(addHours(new Date(filterStore.currentStartDate), value), 'dd MMM yyyy HH:mm')
          }
          onChange={handleSlideTimeframe}
          onChangeCommitted={handleSlideTimeframe}
          ValueLabelComponent={ValueLabelComponent}
          marks={timeframeMarks}
        />
      </div>
      <div className="row slider-label-container">
        <div className="col">
          <Typography variant="caption">
            {format(filterStore.currentStartDate, 'd MMM yyyy')}
          </Typography>
        </div>
        <div className="col align-right">
          <Typography variant="caption">
            {format(endOfDay(filterStore.currentEndDate), 'd MMM yyyy')}
          </Typography>
        </div>
      </div>
    </>
  ));
});

export { TimeframeSlider };

import { useState } from 'react';

import { useDebouncedValue } from '@/modules/utils/useDebouncedValue';

//#region state shape
export interface State {
  searchTerm: string;
}

const initState: State = {
  searchTerm: '',
};
//#endregion

//#region hook
const useSearchTerm = ({ searchTerm: initSearchTerm } = initState) => {
  const [searchTerm, setSearchTerm] = useState(initSearchTerm);

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);

  return {
    searchTerm,
    debouncedSearchTerm,
    setSearchTerm: (s = '') => setSearchTerm(s),
  };
};
//#endregion

export { useSearchTerm };

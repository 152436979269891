import React, { useCallback } from 'react';
import { Typography, Button, Dialog, Divider } from '@material-ui/core';
import { subDays, startOfDay } from 'date-fns';

import { DateRange } from '@/components/DateRange';
import { useSetState } from 'react-use';
import { DateFilterProps } from '@/components/Maps/_components/DateFilterCard';

import './style.scss';

interface DateFilterModalProps extends DateFilterProps {
  open: boolean;
  onClose?: () => void;
}

const DateFilterModal = ({
  open,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
  onClose,
}: DateFilterModalProps) => {
  const [dates, setDates] = useSetState({
    startDate,
    endDate,
  });

  const handleShowLastNDays = useCallback(
    (n: number) => {
      setDates({
        startDate: subDays(startOfDay(new Date()), n),
        endDate: startOfDay(new Date()),
      });
    },
    [setDates]
  );

  const handleChangeDate = useCallback(
    (startDate: Date, endDate: Date) => {
      setDates({
        startDate,
        endDate,
      });
    },
    [setDates]
  );

  const applyChanges = useCallback(() => {
    onChange(dates.startDate, dates.endDate);

    if (onClose) {
      onClose();
    }
  }, [dates.endDate, dates.startDate, onChange, onClose]);

  const handleClose = useCallback(() => {
    setDates({
      startDate,
      endDate,
    });
    if (onClose) {
      onClose();
    }
  }, [endDate, onClose, setDates, startDate]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" scroll="body">
      <div>
        <div className="filter-date-picker-header">
          <Typography variant="h5" className="filter-date-picker-header-title">
            Show the last
          </Typography>
          <Button
            className="filter-date-picker-button"
            onClick={() => handleShowLastNDays(7)}
            variant="contained"
          >
            7 Days
          </Button>
          <Button
            className="filter-date-picker-button"
            onClick={() => handleShowLastNDays(30)}
            variant="contained"
          >
            30 Days
          </Button>
          <Button
            className="filter-date-picker-button"
            onClick={() => handleShowLastNDays(365)}
            variant="contained"
          >
            365 Days
          </Button>
          <div className="filter-date-picker-instruction-container">
            <Typography className="filter-date-picker-instruction">
              or select custom dates below
            </Typography>
          </div>
        </div>
        <div>
          <DateRange
            startDate={dates.startDate}
            endDate={dates.endDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChangeDate}
          />
        </div>
        <Divider />
        <div className="filter-date-picker-actions ">
          <Button
            className="filter-date-picker-action "
            color="primary"
            variant="contained"
            onClick={applyChanges}
          >
            Apply
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { DateFilterModal };

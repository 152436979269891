import { useConfig } from './useConfig';
import axios from 'axios';
import { AuthState } from '../auth';

export const useApiService = () => {
  const { __APP_CONFIG__ } = useConfig();
  const { authHeader } = AuthState.useContainer();

  const apiClient = axios.create({
    baseURL: __APP_CONFIG__.api.baseUrl,
  });

  apiClient.interceptors.request.use((config) => {
    config.headers.Authorization = authHeader?.Authorization;
    return config;
  });

  return apiClient;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { Select as MuiSelect, MenuItem } from '@material-ui/core';

interface SelectProps {
  value: any;
  onChange: (event: any) => void;
  options: GenericOption[];
  classes: any;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}
const Select = memo((props: SelectProps) => {
  const { options } = props;
  return (
    <MuiSelect {...props}>
      {options.map((option: GenericOption, idx: number) => (
        <MenuItem value={option.value} key={`location_option_${option.value}_${idx}`}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
});

export { Select };

import { AutocompleteTextField } from '@/components/fields/AutocompleteTextField';
import React, { memo, useCallback, useMemo } from 'react';
import { LocationSelectorProps } from './types';
import './style.scss';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useConfig } from '@/modules/app/useConfig';
import _ from 'lodash';
import { DEFAULT_LOCATION_CONFIG } from '@/config/defaultLocationConfig';
import { DestinationsState } from '@/modules/destination';

const { __APP_CONFIG__ } = useConfig();
const locationConfig = _.mergeWith(
  {},
  DEFAULT_LOCATION_CONFIG,
  __APP_CONFIG__.location,
  (defaultValue, configValue) =>
    _.isNull(configValue) || _.isEmpty(configValue) ? defaultValue : configValue
);

const defaultLocationValue = {
  value: locationConfig.defaultLocation.name,
  label: locationConfig.defaultLocation.name,
} as GenericOption;

const LocationSelector = memo((props: LocationSelectorProps) => {
  const { destinationsAll } = DestinationsState.useContainer();

  const handleAddNewLocation = useCallback(() => {
    window.open(locationConfig.addLocationUrl, locationConfig.addLocationUrlBehaviour);
  }, []);

  const locationOptions = useMemo(() => {
    return destinationsAll.map((destination) => {
      return {
        value: destination.name,
        location: {
          lat: destination.referencePoint?.lat,
          lng: destination.referencePoint?.lon,
        } as Geolocation,
        label: destination.name,
      };
    });
  }, [destinationsAll]);

  return (
    <div className="location-selector-container">
      <div className="location-selector">
        <AutocompleteTextField
          {...props}
          options={locationOptions}
          fullWidth
          classes={{
            root: 'auto-complete-root',
          }}
          defaultValue={defaultLocationValue}
        />
      </div>
      <div>
        <Tooltip title="Add New Location">
          <IconButton color="primary" onClick={handleAddNewLocation}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
});

export { LocationSelector };

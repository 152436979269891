import {
  DestinationSearchRequest,
  LatLong,
  Destinations,
  Destination,
  DestinationRef,
} from '@travello/destination-service/js/destination_service_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import { useApiServices } from '@/modules/app/useApiServices';
import { AuthState } from '../auth';

import { Observable } from 'rxjs';

const deriveDestinationFromDestinations = (d: Destinations) => {
  return d.getDestinationList().map((v) => v.toObject());
};

//#region hook
const useRepository = () => {
  const { destinationServiceClient: serviceClient } = useApiServices();
  const { authHeader } = AuthState.useContainer();

  function getAllDestinations$() {
    return new Observable<Destination.AsObject>((obs) => {
      serviceClient.getAllDestinations(new Empty(), authHeader, (error, response) => {
        if (error) {
          return obs.error(error);
        }
        if (response) {
          deriveDestinationFromDestinations(response).map(obs.next.bind(obs));
          return obs.complete();
        }
      });
    });
  }

  function findDestinations$({ query, location }: DestinationSearchRequest.AsObject) {
    return new Observable<Destination.AsObject>((obs) => {
      const request = new DestinationSearchRequest();
      if (query) {
        request.setQuery(query);
      }
      if (location) {
        const l = new LatLong();
        l.setLat(Number(location.lat));
        l.setLon(Number(location.lon));
        request.setLocation(l);
      }

      serviceClient.findDestinations(request, authHeader, (error, response) => {
        if (error) {
          return obs.error(error);
        }
        if (response) {
          deriveDestinationFromDestinations(response).map(obs.next.bind(obs));
          return obs.complete();
        }
      });
    });
  }

  function getDestination$({ id }: DestinationRef.AsObject) {
    return new Observable<Destination.AsObject>((obs) => {
      const request = new DestinationRef();
      request.setId(id);

      serviceClient.getDestination(request, authHeader, (error, response) => {
        if (error) {
          return obs.error(error);
        }
        obs.next(response.toObject());
        obs.complete();
      });
    });
  }

  return {
    findDestinations$,
    getAllDestinations$,
    getDestination$,
  };
};
//#endregion

export { useRepository };

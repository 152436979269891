import React, { memo } from 'react';
import { IconButton } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

interface MemoziedPlayButtonProps {
  isPlaying: boolean;
  onPause: () => void;
  onPlay: () => void;
}

const PlayButton = memo(({ isPlaying, onPause, onPlay }: MemoziedPlayButtonProps) => {
  return (
    <IconButton onClick={isPlaying ? () => onPause() : () => onPlay()}>
      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
    </IconButton>
  );
});

export { PlayButton };

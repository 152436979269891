import { useEffect, useMemo, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useHarmonicIntervalFn } from 'react-use';
import { retrieveAuthFromCookie } from '@travello/sso';
import { Metadata } from 'grpc-web';

export type useAuthType = () => {
  isIFrame: boolean;
  user: void | firebase.User;
  initialising: boolean;
  error: void | firebase.auth.Error;
  logout: () => void;
  authHeader?: Metadata;
};

const useAuth: useAuthType = () => {
  const auth = firebase.auth();
  const [user, initialising, error] = useAuthState(auth);
  const [idToken, setIdToken] = useState<string>();
  const [expirationTime, setExpirationTime] = useState<number>();

  useEffect(() => {
    retrieveAuthFromCookie();
  }, []);

  const logout = () => {
    auth.signOut();
  };

  const refreshToken = async () => {
    if (!user) {
      setIdToken(undefined);
      return;
    }
    const r = await user.getIdTokenResult(true);
    setIdToken(r.token);
    setExpirationTime(new Date(r.expirationTime).getTime());
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useHarmonicIntervalFn(
    () => {
      if (typeof expirationTime !== 'undefined' && expirationTime <= Date.now() - 300000) {
        // refresh token 5 mins before expiry
        refreshToken();
      }
    },
    user ? 60000 : null // check every 1 minute
  );

  const authHeader = useMemo(() => {
    if (idToken) {
      return {
        Authorization: `Bearer ${idToken}`,
      } as Metadata;
    }
  }, [idToken]);

  return {
    isIFrame: window.self !== window.top,
    user: (!!authHeader && user) || undefined,
    initialising,
    authHeader,
    error,
    logout,
  };
};

export { useAuth };

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Map as LeafletMap, TileLayer, ScaleControl } from 'react-leaflet';
import { Typography } from '@material-ui/core';
import { MarkerList } from '@/components/MarkerList';
import { useUserMovementApi } from '@/modules/userMovement/useMovementApi';
import {
  addDays,
  addHours,
  differenceInDays,
  startOfDay,
  endOfDay,
  format,
  subDays,
  isBefore,
} from 'date-fns';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '@/stores/rootStore';
import { FilteredUserLocation } from '@/stores/markerStore';
import { LoadingPanel } from '@/components/LoadingPanel';

import './style.scss';
import 'leaflet/dist/leaflet.css';
import { LeafletEvent } from 'leaflet';

const EXPIRED_DATE_RANGE = parseInt(process.env.REACT_APP_EXPIRED_DATA_IN_DAYS as string, 10);
const TICKS_INTERVAL = parseInt(process.env.REACT_APP_TICKS_INTERVAL as string, 10);
const KEY_DATE_FORMAT = process.env.REACT_APP_KEY_DATE_FORMAT as string;

const MAPS = () => {
  const { mapControlStore, filterStore, markerStore } = useStore();

  const mapRef = useRef<LeafletMap>(null);
  // Request Tracker
  const [requestStartDate, setRequestStartDate] = useState<Date>(
    new Date(filterStore.currentStartDate)
  );
  const [requestEndDate, setRequestEndDate] = useState<Date>(new Date(filterStore.currentEndDate));

  const [reset, setReset] = useState(false);
  const [onGoingRequest, setOnGoingRequest] = useState(false);

  const { getUserMovements } = useUserMovementApi();

  let requestTimeout: NodeJS.Timeout;
  let timeInterval: NodeJS.Timeout;

  useEffect(() => {
    setOnGoingRequest(true);
    getMapData().then(
      () => {
        setReset(false);
        mapControlStore.setIsPlaying(true);
        setOnGoingRequest(false);
      },
      (error) => console.log(error.message)
    );
  }, []);
  // Request user movement data from API
  useEffect(() => {
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }
    requestTimeout = setTimeout(() => {
      if (!onGoingRequest) {
        setOnGoingRequest(true);
        getMapData().then(
          () => {
            if (!mapControlStore.isPlaying && reset) {
              mapControlStore.setIsPlaying(true);
              setReset(false);
            }
            setOnGoingRequest(false);
          },
          (error) => console.log(error.message)
        );
      }
    }, 1500);

    return () => {
      if (requestTimeout) {
        clearTimeout(requestTimeout);
      }
    };
  }, [
    markerStore.currentPendingUserLocations,
    reset,
    mapControlStore.currentSpeed,
    onGoingRequest,
  ]);

  useEffect(() => {
    if (mapControlStore.isRenderDateTimeMovedManually) {
      if (requestTimeout) {
        clearTimeout(requestTimeout);
      }
      mapControlStore.setIsRenderDateTimeMovedManually(false);
      //Note: need to check if data on the selected current render date time is exist or not to prevent duplicate data requested to server
      const isDataExisted = markerStore.isDataOnSelectedDateExisted(
        mapControlStore.currentRenderDateTime
      );
      if (!isDataExisted) {
        markerStore.resetData();
        setRequestStartDate(new Date(mapControlStore.currentRenderDateTime));
        mapControlStore.setIsPlaying(false);
        setReset(true);
      } else {
        mapControlStore.setIsPlaying(true);
      }
    }
  }, [mapControlStore.isRenderDateTimeMovedManually]);
  // When Filter Start-End date Changed
  useEffect(() => {
    mapControlStore.setRenderDateTime(new Date(filterStore.currentStartDate));
    mapControlStore.setIsPlaying(false);
    setRequestStartDate(new Date(filterStore.currentStartDate));
    setRequestEndDate(new Date(filterStore.currentEndDate));
    markerStore.resetData();
    setOnGoingRequest(false);
    setReset(true);
  }, [filterStore.currentStartDate, filterStore.currentEndDate]);
  // When Location or Filter Changed
  useEffect(() => {
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }
    setRequestStartDate(startOfDay(mapControlStore.currentRenderDateTime));
    mapControlStore.setIsPlaying(false);
    markerStore.resetData();
    setOnGoingRequest(false);
    setReset(true);
  }, [
    mapControlStore.currentLocation.lat,
    mapControlStore.currentLocation.lng,
    filterStore.currentFilters,
  ]);
  // Handle re-render value for Time and Markers
  useEffect(() => {
    if (isBefore(addHours(mapControlStore.currentRenderDateTime, 1), endOfDay(requestEndDate))) {
      timeInterval = setInterval(() => {
        mapControlStore.setRenderDateTime(addHours(mapControlStore.currentRenderDateTime, 1));
      }, TICKS_INTERVAL / mapControlStore.currentSpeed);
    } else if (mapControlStore.isPlaying) {
      mapControlStore.setRenderDateTime(addHours(mapControlStore.currentRenderDateTime, 1));
      mapControlStore.setIsPlaying(false);
    }

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
    };
  }, [mapControlStore.currentRenderDateTime, mapControlStore.currentSpeed]);

  const getMapData = useCallback(async () => {
    const currentRenderTime = mapControlStore.currentRenderDateTime;
    const requestMoment = requestStartDate;
    const renderTimeMoment = subDays(currentRenderTime, EXPIRED_DATE_RANGE);
    const renderTimeMomentEarliest = startOfDay(currentRenderTime);
    if (
      differenceInDays(requestMoment, renderTimeMomentEarliest) <= EXPIRED_DATE_RANGE &&
      requestMoment <= requestEndDate
    ) {
      setRequestStartDate(addDays(requestStartDate, 1));
      try {
        const result: FilteredUserLocation[] = await getUserMovements(
          startOfDay(requestMoment),
          endOfDay(requestMoment),
          mapControlStore.currentLocation.lat,
          mapControlStore.currentLocation.lng,
          filterStore.currentFilters
        );
        const expiredkey = format(renderTimeMoment, KEY_DATE_FORMAT);
        const key = format(startOfDay(requestMoment), KEY_DATE_FORMAT);
        markerStore.refreshPendingUserLocation(result, key, expiredkey);
      } catch (exception) {
        return exception;
      }
    }
  }, [markerStore.currentPendingUserLocations, requestStartDate]);

  const memoizedGeojson = useMemo(() => {
    const map = mapRef.current ? mapRef.current.leafletElement : null;
    return markerStore.calculateShowedMarkers(map, mapControlStore.currentRenderDateTime);
  }, [
    mapControlStore.currentRenderDateTime,
    mapControlStore.isPlaying,
    mapControlStore.currentZoomLevel,
  ]);

  const showedMarkerCounts = useMemo(() => {
    return memoizedGeojson.map((showedGeoJson) => {
      return showedGeoJson.filter((x) => x.geometry.coordinates.length > 0).length;
    });
    //return memoizedGeojson.filter((x) => x.geometry.coordinates.length > 0).length;
  }, [memoizedGeojson]);

  const handleZoomChange = useCallback(
    (event: LeafletEvent) => {
      mapControlStore.setZoomLevel(event.target.getZoom());
    },
    [mapControlStore]
  );

  return useObserver(() => (
    <div className="map-container">
      {markerStore.currentPendingUserLocations.length === 0 && <LoadingPanel />}

      <div className="map-information-container">
        <div className="map-information">
          <div className="date-container">
            <Typography>
              {format(mapControlStore.currentRenderDateTime, 'd MMM y - HH:mm')}
            </Typography>
          </div>
          <div className="dots-container">
            {filterStore.currentFilters.map((filter, index: number) => (
              <div className="dot-container" key={`dot-index-${index}`}>
                <div className={`dot marker-custom-${index}`} />
                <Typography>{showedMarkerCounts[index] || 0}</Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
      <LeafletMap
        ref={mapRef}
        center={[mapControlStore.currentLocation.lat, mapControlStore.currentLocation.lng]}
        zoom={mapControlStore.currentZoomLevel}
        minZoom={mapControlStore.minZoom}
        maxZoom={mapControlStore.maxZoom}
        className="maps"
        preferCanvas={true}
        dragging={false}
        doubleClickZoom="center"
        scrollWheelZoom="center"
        touchZoom="center"
        onzoomend={handleZoomChange}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <ScaleControl maxWidth={240} />
        {memoizedGeojson.map((geojson, index: number) => (
          <MarkerList
            speed={mapControlStore.currentSpeed}
            renderTime={mapControlStore.currentRenderDateTime}
            showedGeoJson={geojson}
            index={index}
            key={`marker_list_${index}`}
          />
        ))}
      </LeafletMap>
    </div>
  ));
};

export { MAPS as Maps };

import React, { useContext } from 'react';

import { useConfig } from './useConfig';
import { DestinationServiceClient } from '@travello/destination-service/js/destination_service_grpc_web_pb';

interface ApiServicesContextValue {
  destinationServiceClient: DestinationServiceClient;
}

const ApiServicesContext = React.createContext<ApiServicesContextValue | null>(null);

export const ApiServicesProvider: React.FC = ({ children }) => {
  const { __APP_CONFIG__ } = useConfig();
  const clients = {
    destinationServiceClient: new DestinationServiceClient(
      __APP_CONFIG__.services.destination.hostname,
      null, // "credentials" is not supported https://github.com/grpc/grpc-web/issues/351
      null
    ),
  };

  return <ApiServicesContext.Provider value={clients}>{children}</ApiServicesContext.Provider>;
};

export type UseApiServicesHook = () => ApiServicesContextValue;

const useApiServices: UseApiServicesHook = () => {
  const value = useContext(ApiServicesContext);
  if (!value) {
    throw Error('ApiServiceContext is not set');
  }
  return value;
};

export { useApiServices };

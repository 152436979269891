import React, { memo, useState } from 'react';
import { Typography, Paper, IconButton } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { DateFilterModal } from '@/components/DateFilterModal';
import './style.scss';

export interface DateFilterProps {
  startDate: Date;
  endDate: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: (startDate: Date, endDate: Date) => void;
}

const DateFilterCard = (props: DateFilterProps) => {
  const { startDate, endDate } = props;
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Paper className="date-filter-card">
        <div className="filter-date-container">
          <div className="filter-date-row">
            <div className="filter-date-label">
              <Typography component="span">From</Typography>
            </div>
            <Typography className="filter-date-value" component="span">
              {format(startDate, 'd MMM yyyy')}
            </Typography>
          </div>
          <div className="filter-date-row">
            <span className="filter-date-label">
              <Typography component="span">to</Typography>
            </span>
            <Typography className="filter-date-value" component="span">
              {format(endDate, 'd MMM yyyy')}
            </Typography>
          </div>
        </div>
        <div>
          <IconButton color="primary" onClick={() => setOpenModal(true)}>
            <DateRangeIcon />
          </IconButton>
        </div>
      </Paper>
      <DateFilterModal {...props} open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

const MemoizedDateFilterCard = memo(DateFilterCard);

export { MemoizedDateFilterCard as DateFilterCard };

import React, { ReactElement } from 'react';
import { Container } from '@material-ui/core';
import './style.scss';

interface MainContainerProps {
  children: ReactElement;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

const mainContainer = (props: MainContainerProps) => {
  const { children, maxWidth } = props;
  return (
    <Container maxWidth={maxWidth ? maxWidth : false} classes={{ root: 'container' }}>
      {children}
    </Container>
  );
};

export { mainContainer as MainContainer };

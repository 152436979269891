import React from 'react';
import { useConfig } from '../app/useConfig';
import { AuthState } from '.';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { MainContainer } from '@/components/MainContainer';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import './style.scss';

const AuthGuard: React.FC = ({ children }) => {
  const { user, initialising, error } = AuthState.useContainer();
  const { __APP_CONFIG__ } = useConfig();

  if (initialising) {
    return (
      <MainContainer>
        <div className="loading-indicator-container">
          <LoadingIndicator />
        </div>
      </MainContainer>
    );
  }

  return !error && user ? (
    <>{children}</>
  ) : (
    <MainContainer maxWidth="md">
      <Card>
        <CardHeader title="Login" />
        <CardContent>
          <StyledFirebaseAuth
            uiConfig={{
              siteName: 'Travello Location Visualisation',
              credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
              // Popup signin flow rather than redirect flow.
              signInFlow: 'popup',
              // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
              signInSuccessUrl: '/',
              // We will display Google and Facebook as auth providers.
              signInOptions: __APP_CONFIG__.firebaseui?.signInOptions ?? [],
            }}
            firebaseAuth={firebase.auth()}
            uiCallback={async () => {
              const auth = firebase.auth();
              const user = auth.currentUser;
              if (!user) {
                return;
              }
              const r = await user.getIdTokenResult();
              if (
                __APP_CONFIG__.auth?.loginPolicy !== undefined &&
                !__APP_CONFIG__.auth?.loginPolicy?.(r)
              ) {
                return auth.signOut();
              }
            }}
          />
        </CardContent>
      </Card>
    </MainContainer>
  );
};

const AuthProvider: React.FC = ({ children }) => {
  return (
    <AuthState.Provider>
      <AuthGuard>{children}</AuthGuard>
    </AuthState.Provider>
  );
};

export { AuthGuard, AuthProvider };

import { createContext, useContext } from 'react';
import { MapControlStore } from './mapControlStore';
import { FilterStore } from './filterStore';
import { MarkerStore } from './markerStore';
import { useConfig } from '../modules/app/useConfig';
import _ from 'lodash';
import { DEFAULT_MAP_CONFIG } from '@/config/defaultMapConfig';
import { DEFAULT_FILTER_CONFIG } from '@/config/defaultFilterConfig';
import { DEFAULT_LOCATION_CONFIG } from '@/config/defaultLocationConfig';

const { __APP_CONFIG__ } = useConfig();

const mapConfig = _.mergeWith(
  {},
  DEFAULT_MAP_CONFIG,
  __APP_CONFIG__.map,
  (defaultValue, configValue) =>
    _.isNull(configValue) || _.isEmpty(configValue) ? defaultValue : configValue
);

const filterConfig = _.mergeWith(
  {},
  DEFAULT_FILTER_CONFIG,
  __APP_CONFIG__.filter,
  (defaultValue, configValue) =>
    _.isNull(configValue) || _.isEmpty(configValue) ? defaultValue : configValue
);

const locationConfig = _.mergeWith(
  {},
  DEFAULT_LOCATION_CONFIG,
  __APP_CONFIG__.location,
  (defaultValue, configValue) =>
    _.isNull(configValue) || _.isEmpty(configValue) ? defaultValue : configValue
);

type RootStoreProps = {
  mapControlStore: MapControlStore;
  filterStore: FilterStore;
  markerStore: MarkerStore;
};

export const rootStore: RootStoreProps = {
  mapControlStore: new MapControlStore(
    mapConfig.defaultValue.defaultSpeed,
    locationConfig.defaultLocation.name,
    locationConfig.defaultLocation.location,
    mapConfig.defaultValue.defaultStartDate,
    mapConfig.defaultValue.defaultZoom,
    mapConfig.defaultValue.defaultMinZoom,
    mapConfig.defaultValue.defaultMaxZoom
  ),
  filterStore: new FilterStore(
    mapConfig.defaultValue.defaultStartDate,
    mapConfig.defaultValue.defaultEndDate,
    filterConfig.defaultValue
  ),
  markerStore: new MarkerStore(),
};

export const RootStoreContext = createContext(rootStore);

export const useStore = () => {
  return useContext(RootStoreContext);
};

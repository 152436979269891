import React, { useState } from 'react';
import L, { LatLng, Layer } from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import { Drift_Marker as DriftMarker } from 'leaflet-drift-marker';

interface GeoJsonLayerProps {
  geoJsonKey: string;
  geoJsonData: GeoJSON.Feature<GeoJSON.Point>[];
  speed?: number;
  index: number;
}

const GEOJSONLAYER = ({ geoJsonKey, geoJsonData, speed = 1, index = 0 }: GeoJsonLayerProps) => {
  const [userMarkers, setUserMarkers] = useState<DriftMarker[]>([]);

  const pointToLayer = (geoJsonPoint: GeoJSON.Feature<GeoJSON.Point>, latlng: LatLng): Layer => {
    const iconSize = 8;
    const idx = geoJsonPoint.properties ? geoJsonPoint.properties.userId : userMarkers.length + 1;
    const customIcon = L.divIcon({
      className: `marker-custom-${index}`,
      iconSize: L.point(iconSize, iconSize, true),
      html: `<span />`,
    });
    const marker =
      userMarkers[idx] ||
      new DriftMarker([latlng.lat, latlng.lng], {
        icon: customIcon,
      });
    marker.slideTo(latlng, {
      duration: 1000 / speed,
    });
    userMarkers[idx] = marker;
    setUserMarkers(userMarkers);

    return marker;
  };

  return (
    <GeoJSON
      key={geoJsonKey}
      data={geoJsonData.filter(
        (x: GeoJSON.Feature<GeoJSON.Point>) => x.geometry.coordinates.length > 0
      )}
      pointToLayer={pointToLayer}
    />
  );
};

export { GEOJSONLAYER as GeoJsonLayer };

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface AutocompleteTextFieldProps {
  defaultValue?: GenericOption;
  onChange: (event: any, value: GenericOption | null) => void;
  options: GenericOption[];
  classes: any;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  disableClearable?: boolean;
  getOptionDisabled?: (option: GenericOption) => boolean;
  onInputChange?: (event: any, value: string) => void;
}
const AutocompleteTextField = memo((props: AutocompleteTextFieldProps) => {
  return (
    <Autocomplete
      {...props}
      autoComplete
      filterSelectedOptions
      getOptionLabel={(option?: GenericOption) => option?.label || ''}
      renderInput={(params: any) => (
        <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} />
      )}
    />
  );
});

export { AutocompleteTextField };

import React, { memo, useCallback } from 'react';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Grid, Hidden } from '@material-ui/core';

import './style.scss';
import { DateFilterProps } from 'components/Maps/_components/DateFilterCard';
import { HighlightedDay } from './HighlightedDay';
import { isBefore } from 'date-fns';
import { isAfter } from 'date-fns/esm';

class DateUtils extends DateFnsUtils {
  getWeekdays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  }
}

const DateRange = ({ startDate, endDate, minDate, maxDate, onChange }: DateFilterProps) => {
  const renderHighlightedDay = useCallback(
    (
      date: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dayComponent: any
    ) => {
      return (
        <HighlightedDay
          date={date}
          dayInCurrentMonth={dayInCurrentMonth}
          dayComponent={dayComponent}
          startDate={startDate}
          endDate={endDate}
        />
      );
    },
    [startDate, endDate]
  );

  const handleChangeStartDate = useCallback(
    (date: MaterialUiPickersDate) => {
      const dateObject = date ? new Date(date.valueOf()) : new Date();
      if (isAfter(dateObject, endDate)) {
        onChange(dateObject, dateObject);
      } else {
        onChange(dateObject, endDate);
      }
    },
    [endDate, onChange]
  );

  const handleChangeEndDate = useCallback(
    (date: MaterialUiPickersDate) => {
      const dateObject = date ? new Date(date.valueOf()) : new Date();

      if (isBefore(dateObject, startDate)) {
        onChange(dateObject, dateObject);
      } else {
        onChange(startDate, dateObject);
      }
    },
    [onChange, startDate]
  );

  return (
    <MuiPickersUtilsProvider utils={DateUtils}>
      <Hidden smDown>
        <div className="date-range-picker-container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Calendar
                date={startDate}
                onChange={handleChangeStartDate}
                renderDay={renderHighlightedDay}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Calendar
                date={endDate}
                onChange={handleChangeEndDate}
                renderDay={renderHighlightedDay}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
          </Grid>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div>
          <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
            <Grid item>
              <Calendar
                date={startDate}
                onChange={handleChangeStartDate}
                renderDay={renderHighlightedDay}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
            <Grid item>
              <Calendar
                date={endDate}
                onChange={handleChangeEndDate}
                renderDay={renderHighlightedDay}
                disableFuture
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
          </Grid>
        </div>
      </Hidden>
    </MuiPickersUtilsProvider>
  );
};

const MemoizedDateRange = memo(DateRange);

export { MemoizedDateRange as DateRange };

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import { DashboardPage } from './pages/Dashboard';
import { AuthProvider } from './modules/auth/authGuard';
import { RootStoreContext, rootStore } from './stores/rootStore';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { DestinationsState } from './modules/destination';
import { ApiServicesProvider } from './modules/app/useApiServices';

const app = () => {
  return (
    <ThemeProvider theme={theme}>
      <ApiServicesProvider>
        <AuthProvider>
          <RootStoreContext.Provider value={rootStore}>
            <DestinationsState.Provider>
              <Router>
                <Switch>
                  <Route exact path="/" component={DashboardPage} />
                </Switch>
              </Router>
            </DestinationsState.Provider>
          </RootStoreContext.Provider>
        </AuthProvider>
      </ApiServicesProvider>
    </ThemeProvider>
  );
};

export { app as App };

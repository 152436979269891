import { Filter } from '@/stores/filterStore';
import { useStore } from '@/stores/rootStore';
import { Button, IconButton, Paper, Typography, Tooltip } from '@material-ui/core';
import React, { useState, memo, useCallback, useMemo } from 'react';
import { FilterModal } from '../../../FilterModal';
import DeleteIcon from '@material-ui/icons/Delete';

import './style.scss';

interface FilterCardProps {
  index: number;
  filter: Filter;
  showDelete?: boolean;
}

const FilterCard = memo(({ filter, index, showDelete }: FilterCardProps) => {
  const { filterStore } = useStore();
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const handleOpenFilterModal = useCallback(() => {
    setOpenFilterModal(true);
  }, [setOpenFilterModal]);

  const handleApplyAdd = useCallback(
    (filter: Filter) => {
      filterStore.updateFilter(filter, index);
      setOpenFilterModal(false);
    },
    [filterStore, index]
  );

  const handleDeleteFilter = useCallback(() => {
    filterStore.deleteFilter(index);
  }, [filterStore, index]);

  const generatedGenderLabel = useMemo(() => {
    const genders = [...filter.genders];
    genders[genders.length - 1] = `and ${genders[genders.length - 1]}`;
    const gendersLabel = genders.join(genders.length > 2 ? ', ' : ' ');

    if (filter.genders.length === 0) {
      return (
        <span>
          All <span style={{ fontWeight: 700 }}>genders</span>
        </span>
      );
    } else {
      return <span style={{ fontWeight: 700 }}>{gendersLabel}</span>;
    }
  }, [filter.genders]);

  const generatedCountryLabel = useMemo(() => {
    const nationalities = [...filter.nationalities];
    nationalities[nationalities.length - 1] = `and ${nationalities[nationalities.length - 1]}`;
    const nationalitiesLabel = nationalities.join(nationalities.length > 2 ? ', ' : ' ');

    if (filter.nationalities.length === 0) {
      return (
        <span>
          from <span style={{ fontWeight: 700 }}>All Countries</span>
        </span>
      );
    } else if (filter.nationalities.length < 5) {
      return (
        <span>
          from <span style={{ fontWeight: 700 }}>{nationalitiesLabel}</span>
        </span>
      );
    } else {
      return (
        <span>
          from <span style={{ fontWeight: 700 }}>{filter.nationalities.length} Countries</span>
        </span>
      );
    }
  }, [filter.nationalities]);

  const generatedInterestLabel = useMemo(() => {
    const interests = [...filter.interests];
    interests[interests.length - 1] = `and ${interests[interests.length - 1]}`;
    const interestDescription = interests.join(interests.length > 2 ? ', ' : ' ');

    if (filter.interests.length === 0 || filter.interests.length > 3) {
      return <span style={{ fontWeight: 700 }}>Multiple Interest</span>;
    } else {
      return (
        <span>
          interest in <span style={{ fontWeight: 700 }}>{interestDescription}</span>
        </span>
      );
    }
  }, [filter.interests]);

  const generatedFilterDescription = useMemo(() => {
    return (
      <>
        {generatedGenderLabel} between{' '}
        <span style={{ fontWeight: 700 }}>{`${filter.minAge}-${filter.maxAge}`}</span>{' '}
        {generatedCountryLabel} with {generatedInterestLabel}
      </>
    );
  }, [
    filter.maxAge,
    filter.minAge,
    generatedCountryLabel,
    generatedGenderLabel,
    generatedInterestLabel,
  ]);

  return (
    <>
      <Paper className="filter-card">
        <Tooltip title={generatedFilterDescription}>
          <div className="filter-description-container">
            <Typography style={{ fontSize: 14 }}>{generatedFilterDescription}</Typography>
          </div>
        </Tooltip>
        <div>
          <Button
            color="secondary"
            variant="contained"
            classes={{ root: 'edit-filter-button' }}
            onClick={handleOpenFilterModal}
          >
            Edit
          </Button>
        </div>
        <div className="dot-indicator">
          <div className={`dot-${index}`} />
        </div>
        {showDelete && (
          <div className="delete-container">
            <IconButton size="small" onClick={handleDeleteFilter}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </Paper>
      <FilterModal
        open={openFilterModal}
        onApply={handleApplyAdd}
        onClose={() => setOpenFilterModal(false)}
        filterValue={filter}
      />
    </>
  );
});

export { FilterCard };

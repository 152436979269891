import React from 'react';
import { LoadingIndicator } from '../LoadingIndicator';
import './style.scss';
const LoadingPanel = () => {
  return (
    <div className="loading-panel-container">
      <LoadingIndicator />
    </div>
  );
};

export { LoadingPanel };

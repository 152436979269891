interface DefaultMapValue {
  defaultStartDate: Date;
  defaultEndDate: Date;
  defaultSpeed: number;
  defaultZoom: number;
  defaultMaxZoom: number;
  defaultMinZoom: number;
}

interface DefaultMapConfig {
  defaultValue: DefaultMapValue;
  speedOptions: SpeedOption[];
}

function parseDateString(dateString: string) {
  const arr = dateString.split(/\D/);
  return new Date(
    parseInt(arr[0], 10),
    parseInt(arr[1], 10) - 1,
    parseInt(arr[2], 10),
    parseInt(arr[3], 10),
    parseInt(arr[4], 10),
    parseInt(arr[5], 10)
  );
}

const DEFAULT_MAP_CONFIG: DefaultMapConfig = {
  defaultValue: {
    defaultStartDate: parseDateString('2020-01-01T00:00:00'),
    defaultEndDate: parseDateString('2020-01-31T23:59:59'),
    defaultSpeed: 1,
    defaultZoom: 9,
    defaultMaxZoom: 13,
    defaultMinZoom: 6,
  },
  speedOptions: [
    {
      value: 0.5,
      label: '0.5x',
    },
    {
      value: 1,
      label: '1x',
    },
    {
      value: 1.5,
      label: '1.5x',
    },
    {
      value: 2,
      label: '2x',
    },
    {
      value: 3,
      label: '3x',
    },
    {
      value: 5,
      label: '5x',
    },
  ],
};

export { DEFAULT_MAP_CONFIG };

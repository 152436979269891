import { useApiService } from '../app/useApi';
import { Filter } from '@/stores/filterStore';
import { format } from 'date-fns';
const useUserMovementApi = () => {
  const apiService = useApiService();
  async function getUserMovements(
    startdate: Date,
    enddate: Date,
    lat: number,
    lng: number,
    filters: Filter[]
  ) {
    const requestData = {
      dateRange: {
        start: format(startdate, 'yyyy-MM-dd HH:mm:ss'),
        end: format(enddate, 'yyyy-MM-dd HH:mm:ss'),
      },
      locationFilter: {
        latitude: lat,
        longitude: lng,
      },
      userFilters: filters,
    };
    try {
      const userMovements = await apiService.post(`/map/user-movements-grouped`, requestData);
      return userMovements.data;
    } catch (exception) {
      // console.log(exception.message);
      return [];
    }
  }

  return {
    getUserMovements,
  };
};

export { useUserMovementApi };

import React, { memo } from 'react';
import {
  isWithinInterval,
  startOfDay,
  format,
  isSameDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfWeek,
  endOfMonth,
  endOfYear,
  endOfDay,
} from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

interface HighlightedDayProps {
  date: MaterialUiPickersDate;
  dayInCurrentMonth: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dayComponent: any;
  startDate: Date;
  endDate: Date;
}

const HighlightedDay = memo(
  ({ date, dayInCurrentMonth, dayComponent, startDate, endDate }: HighlightedDayProps) => {
    const dateObject = date ? new Date(date.valueOf()) : new Date();
    const isFirstDayOfWeek = isSameDay(dateObject, startOfWeek(dateObject));
    const isLastDayOfWeek = isSameDay(dateObject, endOfWeek(dateObject));
    const isFirstDayOfMonth = isSameDay(dateObject, startOfMonth(dateObject));
    const isLastDayOfMonth = isSameDay(dateObject, endOfMonth(dateObject));
    const isFirstDayOfYear = isSameDay(dateObject, startOfYear(dateObject));
    const isLastDayOfYear = isSameDay(dateObject, endOfYear(dateObject));
    const isFirstDay = dayInCurrentMonth && isSameDay(dateObject, startDate);
    const isLastDay = dayInCurrentMonth && isSameDay(dateObject, endDate);
    const isInBetween =
      dayInCurrentMonth &&
      isWithinInterval(dateObject, { start: startOfDay(startDate), end: endOfDay(endDate) });

    const isDisabled = dayComponent.props.disabled;
    return (
      <div
        className={clsx('week-wrapper', {
          highlight: isInBetween,
          firstHighlight:
            isFirstDay ||
            ((isFirstDayOfWeek || isFirstDayOfMonth || isFirstDayOfYear) && isInBetween),
          endHighlight:
            isLastDay || ((isLastDayOfWeek || isLastDayOfMonth || isLastDayOfYear) && isInBetween),
        })}
      >
        {dayInCurrentMonth ? (
          <IconButton
            className={clsx('day', {
              milestone: isFirstDay || isLastDay,
              nonCurrentMonthDay: !dayInCurrentMonth,
              highlightNonCurrentMonthDay: !dayInCurrentMonth && isInBetween,
              disabled: isDisabled,
            })}
            disabled={isDisabled}
          >
            <div>{format(dateObject, 'd')}</div>
          </IconButton>
        ) : (
          <div className="day" />
        )}
      </div>
    );
  }
);

export { HighlightedDay };

interface DefaultLocation {
  name: string;
  location: Geolocation;
}

interface DefaultLocationConfig {
  defaultLocation: DefaultLocation;
  addLocationUrl?: string;
  addLocationUrlBehaviour?: string;
}

const DEFAULT_LOCATION_CONFIG: DefaultLocationConfig = {
  defaultLocation: {
    name: 'Brisbane',
    location: {
      lat: -27.467331464,
      lng: 153.02333324,
    } as Geolocation,
  },
  addLocationUrl: 'https://travello.stage.bluetee.net/portal/catalogue',
  addLocationUrlBehaviour: '_blank',
};

export { DEFAULT_LOCATION_CONFIG };

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';

import { DEFAULT_FILTER_CONFIG } from '../../config/defaultFilterConfig';
import { useConfig } from '../../modules/app/useConfig';
import _ from 'lodash';
import { useSetState } from 'react-use';

import './style.scss';
import { Filter } from '@/stores/filterStore';
import { AutocompleteTextField } from '../fields/AutocompleteTextField';

interface FilterModalProps {
  open: boolean;
  onClose: () => void;
  onApply: (filter: Filter) => void;
  filterValue: Filter;
}

const { __APP_CONFIG__ } = useConfig();

const filterConfig = _.mergeWith(
  {},
  DEFAULT_FILTER_CONFIG,
  __APP_CONFIG__.filter,
  (defaultValue, configValue) =>
    _.isNull(configValue) || _.isEmpty(configValue) ? defaultValue : configValue
);

const FilterModal = ({ open, onClose, onApply, filterValue }: FilterModalProps) => {
  const { ageRangeOptions, genderOptions, nationalityOptions, interestOptions } = filterConfig;
  const [ageRange, setAgeRange] = useSetState({
    minAge: filterValue.minAge,
    maxAge: filterValue.maxAge,
  });
  const [genders, setGenders] = useState<string[]>([...filterValue.genders]);
  const [nationalities, setNationalities] = useState<string[]>([...filterValue.nationalities]);
  const [interests, setInterests] = useState<string[]>([...filterValue.interests]);

  const isGenderOptionChecked = useCallback(
    (value: string) => {
      return (
        (value === '' && genders.length === 0) || genders.find((x) => x === value) !== undefined
      );
    },
    [genders]
  );

  const isNationalityDisabled = useCallback(
    (nationality: GenericOption): boolean => {
      return nationalities.find((x) => x === nationality.label) !== undefined;
    },
    [nationalities]
  );

  const isInterestOptionChecked = useCallback(
    (value: string) => {
      return (
        (value === '' && interests.length === 0) || interests.find((x) => x === value) !== undefined
      );
    },
    [interests]
  );

  const handleAgeRangeChange = useCallback(
    (event: any) => {
      const value = event.target.value as string;
      const values = value.split('-');
      setAgeRange({
        minAge: parseInt(values[0], 10),
        maxAge: parseInt(values[1], 10),
      });
    },
    [setAgeRange]
  );
  const toggleGenderCheckbox = useCallback(
    (gender: string, checked: boolean) => {
      if (gender === '' && checked) {
        setGenders([]);
      } else if (!checked) {
        const newGenders = genders.filter((x: string) => x !== gender);
        setGenders([...newGenders]);
      } else {
        genders.push(gender);
        setGenders([...genders]);
      }
    },
    [genders]
  );

  const handleSelectNationality = useCallback(
    (event: any, value: GenericOption | null) => {
      if (value) {
        const selectedNationality = value.label;
        nationalities.push(selectedNationality);
        setNationalities([...nationalities]);
      }
    },
    [nationalities]
  );

  const handleDeleteNationality = useCallback(
    (nationality: string) => {
      const newNationalities = nationalities.filter((x) => x !== nationality);
      setNationalities(newNationalities);
    },
    [nationalities]
  );

  const toggleInterestCheckbox = useCallback(
    (interest: string, checked: boolean) => {
      if (interest === '' && checked) {
        setInterests([]);
      } else if (!checked) {
        const newInterest = interests.filter((x: string) => x !== interest);
        setInterests([...newInterest]);
      } else {
        interests.push(interest);
        setInterests([...interests]);
      }
    },
    [interests]
  );

  const handleReset = useCallback(() => {
    setAgeRange({
      minAge: filterValue.minAge,
      maxAge: filterValue.maxAge,
    });
    setGenders([...filterValue.genders]);
    setNationalities([...filterValue.nationalities]);
    setInterests([...filterValue.interests]);
  }, [
    filterValue.genders,
    filterValue.interests,
    filterValue.maxAge,
    filterValue.minAge,
    filterValue.nationalities,
    setAgeRange,
  ]);

  const handleApplyFilter = useCallback(() => {
    const newFilter = {
      minAge: ageRange.minAge,
      maxAge: ageRange.maxAge,
      genders,
      nationalities,
      interests,
    } as Filter;
    onApply(newFilter);
  }, [ageRange.maxAge, ageRange.minAge, genders, interests, nationalities, onApply]);

  const handleCloseModal = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <div className="filter-form-header">
          <div className="filter-form-title-container">
            <Typography variant="h4">Filters</Typography>
          </div>
          <div>
            <Button
              variant="outlined"
              classes={{ root: 'filter-reset-all-button' }}
              startIcon={<RefreshIcon />}
              onClick={handleReset}
            >
              Reset all filters
            </Button>
          </div>
          <div>
            <IconButton onClick={handleCloseModal}>
              <ClearIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="filter-field-row">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography className="filter-field-label">Age Range</Typography>
              <Select
                value={`${ageRange.minAge}-${ageRange.maxAge}`}
                fullWidth
                classes={{ root: 'select-dropdown' }}
                disableUnderline
                onChange={handleAgeRangeChange}
              >
                {ageRangeOptions.map((ageRangeOption: AgeRangeOption) => (
                  <MenuItem
                    value={`${ageRangeOption.min}-${ageRangeOption.max}`}
                    key={`age_option_${ageRangeOption.min}_to_${ageRangeOption.max}`}
                  >
                    {ageRangeOption.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <Typography className="filter-field-label">Gender</Typography>
              {genderOptions.map((genderOption: GenericOption) => (
                <FormControlLabel
                  key={`gender_option_${genderOption.value}`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isGenderOptionChecked(genderOption.value)}
                      onChange={(e: any, checked: boolean) =>
                        toggleGenderCheckbox(genderOption.value, checked)
                      }
                    />
                  }
                  label={genderOption.label}
                />
              ))}
            </Grid>
          </Grid>
        </div>

        <Divider />

        <div className="filter-field-row">
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography className="filter-field-label">Nationality</Typography>
              <AutocompleteTextField
                onChange={handleSelectNationality}
                options={nationalityOptions}
                fullWidth
                classes={{
                  root: 'auto-complete-root',
                }}
                getOptionDisabled={isNationalityDisabled}
              />
              {/* <Select
                fullWidth
                value=""
                classes={{ root: 'select-dropdown' }}
                disableUnderline
                onChange={handleSelectNationality}
              >
                {nationalityOptions.map((nationalityOption: NationalityOption) => (
                  <MenuItem
                    key={`nationality_option_${nationalityOption.code}`}
                    value={nationalityOption.name}
                    disabled={isNationalityDisabled(nationalityOption.name)}
                  >
                    {nationalityOption.name}
                  </MenuItem>
                ))}
              </Select> */}
            </Grid>
            <Grid item xs={8}>
              <div className="filter-countries-container">
                {nationalities.map((nationality: string) => (
                  <Chip
                    classes={{ root: 'filter-country-chip', label: 'filter-country-chip-label' }}
                    key={`selected_nationality_${nationality}`}
                    variant="outlined"
                    label={nationality}
                    onDelete={() => handleDeleteNationality(nationality)}
                    deleteIcon={<ClearIcon />}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </div>

        <Divider />

        <div className="filter-field-row">
          <Grid container>
            <Grid item xs={12}>
              <Typography className="filter-field-label">Interests</Typography>
              <Grid container>
                {interestOptions.map((interestOption: GenericOption) => (
                  <Grid item xs={6} md={3} key={`interest_option_${interestOption.value}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={isInterestOptionChecked(interestOption.value)}
                          onChange={(e: any, checked: boolean) =>
                            toggleInterestCheckbox(interestOption.value, checked)
                          }
                        />
                      }
                      label={interestOption.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleApplyFilter}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { FilterModal };

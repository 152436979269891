import { action, decorate, observable, toJS, computed } from 'mobx';

class MapControlStore {
  speed = 0;
  locationName = 'brisbane';
  location = {
    lat: 0,
    lng: 0,
  };
  zoom = 0;
  minZoom = 0;
  maxZoom = 0;
  defaultZoom = 0;

  isPlaying = false;

  renderDateTime: Date = new Date();
  isRenderDateTimeMovedManually = false;

  constructor(
    speed: number,
    locationName: string,
    location: Geolocation = { lat: 0, lng: 0 } as Geolocation,
    renderDateTime: Date = new Date(),
    zoom: number,
    minZoom: number,
    maxZoom: number
  ) {
    this.speed = speed;
    this.locationName = locationName;
    this.location = location;
    this.renderDateTime = renderDateTime;
    this.isPlaying = false;
    this.defaultZoom = zoom;
    this.zoom = zoom;
    this.minZoom = minZoom;
    this.maxZoom = maxZoom;
  }

  get currentLocationName() {
    return this.locationName;
  }

  get currentLocation() {
    return toJS(this.location);
  }

  get currentSpeed() {
    return this.speed;
  }

  get currentRenderDateTime() {
    return this.renderDateTime;
  }

  get currentZoomLevel() {
    return this.zoom;
  }

  setIsPlaying(isPlaying: boolean) {
    this.isPlaying = isPlaying;
  }

  setSpeed(speed: number) {
    this.speed = speed;
  }

  setCurrentLocation(locationName: string, location: Geolocation) {
    this.locationName = locationName;
    this.location = location;
  }

  setRenderDateTime(date: Date, isRenderDateTimeMovedManually = false) {
    if (isRenderDateTimeMovedManually || (!isRenderDateTimeMovedManually && this.isPlaying)) {
      this.renderDateTime = date;
      this.isRenderDateTimeMovedManually = isRenderDateTimeMovedManually;
    }
  }

  setIsRenderDateTimeMovedManually(isRenderDateTimeMovedManually = false) {
    this.isRenderDateTimeMovedManually = isRenderDateTimeMovedManually;
  }

  setZoomLevel(zoom: number) {
    this.zoom = zoom;
  }
}

decorate(MapControlStore, {
  isPlaying: observable,
  speed: observable,
  locationName: observable,
  location: observable,
  renderDateTime: observable,
  zoom: observable,
  minZoom: observable,
  maxZoom: observable,
  defaultZoom: observable,
  isRenderDateTimeMovedManually: observable,
  setSpeed: action,
  setCurrentLocation: action,
  setRenderDateTime: action,
  setIsRenderDateTimeMovedManually: action,
  setIsPlaying: action,
  setZoomLevel: action,
  currentLocationName: computed,
  currentLocation: computed,
  currentSpeed: computed,
  currentRenderDateTime: computed,
  currentZoomLevel: computed,
});

export { MapControlStore };

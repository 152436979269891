import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const themeValue = {
  palette: {
    primary: {
      light: '#33addc',
      main: '#0099D4',
      dark: '#006b94',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#f1fafe',
      main: '#EEF9FE',
      dark: '#a6aeb1',
      contrastText: '#0099D4',
    },
  },
};

let theme = createMuiTheme({
  ...themeValue,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 16,
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };

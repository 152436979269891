/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, decorate, observable, toJS } from 'mobx';
import { differenceInHours, format, isAfter, parse } from 'date-fns';
import { Map as LeafletMap, LatLng } from 'leaflet';
import _ from 'lodash';

const EXPIRED_DURATION = parseInt(
  process.env.REACT_APP_EXPIRED_MARKER_DURATION_IN_HOURS as string,
  10
);
const KEY_DATE_FORMAT = (process.env.REACT_APP_KEY_DATE_FORMAT || 'yyyy-MM-dd') as string;
const KEY_DATETIME_FORMAT = (process.env.REACT_APP_KEY_DATE_TIME_FORMAT ||
  'yyyy-MM-dd HH:mm') as string;
const SERVICE_DATE_FORMAT = (process.env.REACT_APP_SERVICE_DATE_TIME_FORMAT ||
  'yyyy-MM-dd HH:mm:ss X') as string;

function parseUserLocationDate(stringDate: string) {
  return parse(stringDate, SERVICE_DATE_FORMAT, new Date());
}

export interface FilteredUserLocation {
  filter: any;
  groupedUserLocationsByDateTime: Map<string, UserLocation[]>;
}

class MarkerStore {
  showedMarkers: GeoJSON.Feature<GeoJSON.Point>[][] = [];
  pendingUserLocations: Map<string, Map<string, UserLocation[]>>[] = [];

  constructor() {
    this.showedMarkers = [];
    this.pendingUserLocations = [];
  }

  refreshPendingUserLocation(
    newPendingUserLocations: FilteredUserLocation[],
    newkey: string,
    expiredKey: string
  ) {
    newPendingUserLocations.forEach(
      (newPendingUserLocations: FilteredUserLocation, index: number) => {
        const pendingUserLocations =
          this.pendingUserLocations && this.pendingUserLocations.length >= index + 1
            ? this.pendingUserLocations[index]
            : new Map<string, Map<string, UserLocation[]>>();
        pendingUserLocations.delete(expiredKey);
        pendingUserLocations.set(newkey, newPendingUserLocations.groupedUserLocationsByDateTime);
        this.pendingUserLocations[index] = pendingUserLocations;
      }
    );
  }

  calculateShowedMarkers(leafletMap: LeafletMap | null, currentDate: Date) {
    const currentRenderDateKey = format(currentDate, KEY_DATE_FORMAT);
    const currentRenderDateTime = format(currentDate, KEY_DATETIME_FORMAT);
    this.pendingUserLocations.forEach(
      (pendingUserLocations: Map<string, Map<string, UserLocation[]>>, idx: number) => {
        let userMovementToBeRendered = [] as UserLocation[];
        const currentUserMovementsByDate = pendingUserLocations.get(currentRenderDateKey);
        if (currentUserMovementsByDate) {
          userMovementToBeRendered =
            (currentUserMovementsByDate.get
              ? currentUserMovementsByDate.get(currentRenderDateTime)
              : (_.get(currentUserMovementsByDate, currentRenderDateTime) as UserLocation[])) ||
            ([] as UserLocation[]);
        }

        const geoJsonFeatures =
          this.showedMarkers.length >= idx + 1 && this.showedMarkers[idx]
            ? this.showedMarkers[idx].filter((loc: GeoJSON.Feature<GeoJSON.Point>) => {
                if (loc.properties) {
                  const lastUpdated = parseUserLocationDate(loc.properties.date);
                  const lng = loc.geometry.coordinates[0];
                  const lat = loc.geometry.coordinates[1];
                  return (
                    differenceInHours(currentDate, lastUpdated) <= EXPIRED_DURATION &&
                    !isAfter(lastUpdated, currentDate) &&
                    leafletMap &&
                    leafletMap.getBounds().contains(new LatLng(lat, lng))
                  );
                } else {
                  return false;
                }
              })
            : [];

        if (userMovementToBeRendered && userMovementToBeRendered.length > 0) {
          userMovementToBeRendered.forEach((userMovement: UserLocation) => {
            const jsonObj = JSON.parse(userMovement.geoJson);
            if (jsonObj.coordinates.length > 1) {
              const lng = jsonObj.coordinates[0];
              const lat = jsonObj.coordinates[1];
              if (
                (leafletMap && leafletMap.getBounds().contains(new LatLng(lat, lng))) ||
                !leafletMap
              ) {
                const index = geoJsonFeatures.findIndex(
                  (geojsonFeature) => geojsonFeature.id === userMovement.userId
                );

                const features = {
                  id: userMovement.userId,
                  type: 'Feature',
                  geometry: jsonObj,
                  properties: {
                    userId: userMovement.userId,
                    date: userMovement.date,
                  },
                } as GeoJSON.Feature<GeoJSON.Point>;

                if (index >= 0) {
                  geoJsonFeatures.splice(index, 1, features);
                } else {
                  geoJsonFeatures.push(features);
                }
              }
            }
          });
        }
        this.showedMarkers[idx] = geoJsonFeatures;
      }
    );
    return toJS(this.showedMarkers);
  }

  isDataOnSelectedDateExisted(selectedDate: Date) {
    let isExist = false;
    const selectedDateKey = format(selectedDate, KEY_DATE_FORMAT);
    this.pendingUserLocations.forEach((pendingUserLocations) => {
      isExist = isExist || pendingUserLocations.get(selectedDateKey) !== undefined;
    });
    return isExist;
  }

  get isPendingUserLocationsEmpty() {
    return this.pendingUserLocations.length === 0;
  }

  get currentPendingUserLocations() {
    return toJS(this.pendingUserLocations);
  }

  get currentShowedMarkers() {
    return toJS(this.showedMarkers);
  }

  resetData() {
    this.showedMarkers = [];
    this.pendingUserLocations = [];
  }
}

decorate(MarkerStore, {
  showedMarkers: observable,
  pendingUserLocations: observable,
  refreshPendingUserLocation: action,
  resetData: action,
  currentPendingUserLocations: computed,
  isPendingUserLocationsEmpty: computed,
  currentShowedMarkers: computed,
});

export { MarkerStore };

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { Typography, Grid, Button, Paper } from '@material-ui/core';
import _ from 'lodash';
import { DateFilterCard } from '@/components/Maps/_components/DateFilterCard';
import { useConfig } from '@/modules/app/useConfig';
import { DEFAULT_MAP_CONFIG } from '@/config/defaultMapConfig';
import { useObserver } from 'mobx-react-lite';
import { useStore } from '@/stores/rootStore';
import { Select } from '@/components/fields/Select';
import { FilterCard } from '../FilterCard';
import { PlayButton } from './PlayButton';
import { TimeframeSlider } from './TimeframeSlider';
import './style.scss';
import { Filter } from '@/stores/filterStore';
import { AddFilterButton } from './AddFilterButton';
import { LocationSelector } from '../LocationSelector';

const { __APP_CONFIG__ } = useConfig();

const mapConfig = _.mergeWith(
  {},
  DEFAULT_MAP_CONFIG,
  __APP_CONFIG__.map,
  (defaultValue, configValue) =>
    _.isNull(configValue) || _.isEmpty(configValue) ? defaultValue : configValue
);

const MapControl = () => {
  const { mapControlStore, filterStore } = useStore();

  const [speedOptionsOpen, setSpeedOptionsOpen] = useState(false);

  const { speedOptions } = mapConfig;

  const handleChangeDateRange = useCallback(
    (newStartDate: Date, newEndDate: Date) => {
      filterStore.setStartDate(newStartDate);
      filterStore.setEndDate(newEndDate);
    },
    [filterStore.setStartDate, filterStore.setEndDate]
  );

  const handleLocationChange = useCallback(
    (event: any, value: GenericOption | null) => {
      if (value) {
        const selectedLocation = value as LocationOption;
        mapControlStore.setCurrentLocation(selectedLocation.value, selectedLocation.location);
      }
    },
    [mapControlStore.setCurrentLocation]
  );

  const handleSpeedChange = useCallback(
    (event: any) => {
      mapControlStore.setSpeed(parseFloat(event.target.value));
    },
    [mapControlStore.setSpeed]
  );

  const handleOpenSpeedOption = useCallback(() => {
    setSpeedOptionsOpen(true);
  }, [speedOptionsOpen]);

  const handleCloseSpeedOption = useCallback(() => {
    setSpeedOptionsOpen(false);
  }, [speedOptionsOpen]);

  const handleToggleSpeedOption = useCallback(() => {
    setSpeedOptionsOpen(!speedOptionsOpen);
  }, [speedOptionsOpen]);

  const handlePlay = useCallback(() => {
    mapControlStore.setIsPlaying(true);
  }, [mapControlStore.setIsPlaying]);

  const handlePause = useCallback(() => {
    mapControlStore.setIsPlaying(false);
  }, [mapControlStore.setIsPlaying]);

  return useObserver(() => (
    <div className="control-container">
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
        <Grid item container>
          <Grid item xs={6} md={3} lg={2}>
            <Typography className="control-label-location">Showing Traveller Flows for</Typography>
          </Grid>
          <Grid item xs={12} lg={6} classes={{ root: 'location-dropdown-container' }}>
            <LocationSelector onChange={handleLocationChange} />
          </Grid>
        </Grid>
        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <DateFilterCard
              startDate={filterStore.currentStartDate}
              endDate={filterStore.currentEndDate}
              onChange={handleChangeDateRange}
            />
          </Grid>
          <Grid item direction="row" container xs={12} md={9} lg={10} spacing={2}>
            {filterStore.currentFilters.map((filter: Filter, index: number) => (
              <Grid item key={`filter_card_${index}`}>
                <FilterCard filter={filter} index={index} showDelete={index !== 0} />
              </Grid>
            ))}
            {filterStore.currentFilters.length < 2 && (
              <Grid item>
                <AddFilterButton />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={2} lg={1}>
            <Paper>
              <Button
                size="small"
                className="speed-control-container"
                fullWidth
                onClick={handleToggleSpeedOption}
              >
                <Typography>Speed:</Typography>
                <Select
                  fullWidth
                  disableUnderline
                  classes={{
                    root: 'speed-dropdown',
                  }}
                  value={mapControlStore.currentSpeed}
                  onChange={handleSpeedChange}
                  open={speedOptionsOpen}
                  onOpen={handleOpenSpeedOption}
                  onClose={handleCloseSpeedOption}
                  options={speedOptions}
                />
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} md={10} lg={11}>
            <div className="row">
              <div className="play-pause-button-container">
                <PlayButton
                  isPlaying={mapControlStore.isPlaying}
                  onPause={handlePause}
                  onPlay={handlePlay}
                />
              </div>
              <div className="col">
                <TimeframeSlider />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  ));
};

export { MapControl };

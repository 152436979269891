/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, decorate, observable, toJS } from 'mobx';

export interface Filter {
  minAge: number;
  maxAge: number;
  nationalities: string[];
  genders: string[];
  interests: string[];
}

class FilterStore {
  filters: Filter[] = [];

  filter: Filter = {} as Filter;

  startDate: Date = new Date();
  endDate: Date = new Date();

  activeFilterForm: Filter = {} as Filter;

  constructor(startDate: Date, endDate: Date, defaultFilter: Filter) {
    this.startDate = startDate;
    this.endDate = endDate;

    this.filters = [defaultFilter];
    this.filter = defaultFilter;
  }

  get currentStartDate() {
    return this.startDate;
  }

  get currentEndDate() {
    return this.endDate;
  }

  get currentFilters() {
    return toJS(this.filters);
  }

  get defaultFilter() {
    return toJS(this.filter);
  }

  setStartDate(startDate: Date) {
    this.startDate = startDate;
  }

  setEndDate(endDate: Date) {
    this.endDate = endDate;
  }

  addFilter(newFilter: Filter) {
    this.filters.push(newFilter);
  }

  updateFilter(newFilter: Filter, filterIndex: number) {
    this.filters[filterIndex] = newFilter;
  }

  deleteFilter(index: number) {
    if (index === this.filters.length - 1) {
      this.filters.pop();
    }
  }
}

decorate(FilterStore, {
  filters: observable,
  startDate: observable,
  endDate: observable,
  defaultFilter: computed,
  currentStartDate: computed,
  currentEndDate: computed,
  currentFilters: computed,
  setStartDate: action,
  setEndDate: action,
  addFilter: action,
  updateFilter: action,
  deleteFilter: action,
});

export { FilterStore };

import React from 'react';
import { GeoJsonLayer } from '../GeoJsonLayer';
import { format } from 'date-fns';

interface MarkerListProps {
  renderTime: Date;
  showedGeoJson: GeoJSON.Feature<GeoJSON.Point>[];
  speed?: number;
  index: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const markerList = ({ renderTime, showedGeoJson, speed, index }: MarkerListProps) => {
  return (
    <GeoJsonLayer
      speed={speed}
      geoJsonKey={format(renderTime, 'd-M-y HH:mm')}
      geoJsonData={showedGeoJson}
      index={index}
    />
  );
};

export { markerList as MarkerList };

import React, { memo, useState, useCallback } from 'react';

import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import './style.scss';
import { FilterModal } from '@/components/FilterModal';
import { useStore } from '@/stores/rootStore';
import { Filter } from '@/stores/filterStore';

const AddFilterButton = memo(() => {
  const { filterStore } = useStore();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleOpenFilterModal = useCallback(() => {
    setOpenFilterModal(true);
  }, [setOpenFilterModal]);
  const handleApplyAdd = useCallback(
    (filter: Filter) => {
      filterStore.addFilter(filter);
      setOpenFilterModal(false);
    },
    [filterStore]
  );
  return (
    <>
      <Button
        classes={{
          root: 'add-filter-comparison-button',
          label: 'add-filter-comparison-label',
        }}
        onClick={handleOpenFilterModal}
      >
        <div className="add-filter-comparison-icon-container">
          <AddIcon classes={{ root: 'add-filter-comparison-icon' }} />
        </div>
        <div>Add Comparison</div>
      </Button>
      <FilterModal
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        onApply={handleApplyAdd}
        filterValue={filterStore.defaultFilter}
      />
    </>
  );
});

export { AddFilterButton };
